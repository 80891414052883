import React from 'react'
import { useFormikContext } from 'formik'

import Box from 'components/Box'
import Button from 'components/Button'
import Text from 'components/Text'
import Image from 'components/Image'
import { responsive } from 'components/ThemeProvider/theme'

import Captcha from '../Captcha'

import ClaimForm from './ClaimForm'
import EventForm from './EventForm'

import general from './general.png';
import traffic from './traffic.png';
import TraficDataForm from './TraficDataForm'

const applyData = [
  {
    title: '聲請人',
    name: ['聲請人'],
    mode: 'apply',
  },
  {
    title: '對造人',
    name: ['對造人'],
    mode: 'versus',
  }
]

const textsrc = {
  general,
  traffic,
}

const SubmitBtn = ({ handleClose, ...props }) => {
  const { submitForm, isSubmitting, setFieldValue } = useFormikContext()

  return (
    <>
      <Button
        onClick={submitForm}
        type="button"
        disabled={isSubmitting}
        {...props}
      >確認申請</Button>
      <Button
        type="button"
        disabled={isSubmitting}
        onClick={() => {
          setFieldValue('captcha', {})
          handleClose()
        }}
        {...props}
      >修改</Button>
    </>
  )
}

const AllClaimData = ({ id, isOpen, handleOpen, ...props }) => {
  return (
    <Box {...props}>
      {isOpen && (
        <Box
          px={responsive('15%', '40%')}
          pb="1em"
          mb="2em"
          borderBottom="2px solid"
          borderColor="lightPurple"
        >
         <Image src={textsrc[id]} />
        </Box>
      )}
      {applyData.map(({ title, mode, name }, i) => (
        <Box key={i}>
          <Text mb="1.5em" fontSize="1.5em">{title}資料</Text>
          <ClaimForm
            readOnly={isOpen}
            mode={mode}
            index={i}
            name={name}
          />
        </Box>
      ))}
      {id === 'traffic' && <TraficDataForm readOnly={isOpen} id={id} />}
      <EventForm readOnly={isOpen} id={id} />
      {isOpen && (
        <Box textAlign="center">
          <Captcha my="2em" textAlign="left" />
          <SubmitBtn fontSize={responsive('1.125em', '1.25em')} mx="0.5em" handleClose={() => handleOpen(false)} />
        </Box>
      )}
    </Box>
  )
}

export default AllClaimData
