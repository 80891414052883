import React, { useCallback } from 'react'
import { useImmer } from 'use-immer'
import { FastField } from 'formik';
import { MdClose, MdAdd } from 'react-icons/md'

import Box from 'components/Box'
import Button from 'components/Button'
import Board from 'components/Board'
// import Text from 'components/Text'
import Flex from 'components/Flex'
import Input from 'components/Input'
import Label from 'components/Label'
import { responsive } from 'components/ThemeProvider/theme';

import CodeDropdown from '../CodeDropdown';
import DateDropdown from './DateDropdown';
import AdressForm from './AdressForm';

const ClaimForm = ({ index, mode, readOnly, name, ...props }) => {
  const [useData, updateData] = useImmer(name);
  const increase = useCallback((n) => {
    updateData(draft => {
      draft.push(n)
    })
  }, [updateData])

  const remove = useCallback((i) => {
    updateData(draft => {
      draft.splice(i, 1)
    })
  }, [updateData])


  return (
    useData.map((n, i) => {
      const namespace = [mode, i].join('.')
      return (
        <Board mb="1.5em" px={responsive('1em', '2em')} key={i} {...props}>
          <Flex flexDirection={responsive('column', 'column', 'row')} justifyContent={responsive('center', 'space-between')}>
            <Box
              pr={responsive(0, 0, '1.875em')}
              width={responsive(1, 1, 1 / 2)}
              borderRight={responsive('none', 'none', '1px solid')}
              borderColor={responsive('none', 'none', 'purple')}
            >
              <Input.FastField
                label={`${n}（代表人）`}
                name={`${namespace}.name`}
                readOnly={readOnly}
                note={`${n}如有數人的話，請填寫第一${n}之姓名`}
              />
              <Flex.Responsive alignItems={responsive('auto', 'center')} mt={responsive('1em', '2.25em')}>
                <Input.Field
                  mt="1em"
                  label="性別"
                  labelWidth="2.5em"
                  readOnly={readOnly}
                  type="radio"
                  name={`${namespace}.sex`}
                  options={[
                    { title: '男', id: 'm' },
                    { title: '女', id: 'f' }
                  ]}
                />
                <Input.FastField
                  ml={responsive(0, '2em')}
                  label="身分證字號"
                  readOnly={readOnly}
                  name={`${namespace}.national_number`}
                />
              </Flex.Responsive>
              <DateDropdown readOnly={readOnly} name="birth" label="出生年月日" namespace={namespace} />
              <AdressForm readOnly={readOnly} label="通訊地址" namespace={namespace} flexDirection="column" name="address" />
            </Box>
            <Box mt={responsive('1em', '1em', 0)} width={responsive(1, 1, 1 / 2)} pl={responsive(0, 0, '1.875em')} borderLeft={responsive('none', 'none', '1px solid')} borderColor={responsive('none', 'none', 'purple')}>
              <Flex.Responsive alignItems={responsive('auto', 'flex-end')} as="label">
                <Label>稱謂</Label>
                <CodeDropdown.FastField
                  disabled={readOnly}
                  path={`titles/${String.fromCharCode(65 + index)}`}
                  flex={1}
                  name={`${namespace}.titid`}
                />
              </Flex.Responsive>
              <Flex.Responsive alignItems={responsive('auto', 'flex-end')} mt="2.25em">
                <Input.Field
                  label="是否為公司"
                  mt="1em"
                  type="radio"
                  readOnly={readOnly}
                  name={`${namespace}.comtype`}
                  options={[
                    { title: '是', id: 'y' },
                    { title: '否', id: 'n' }
                  ]}
                />
              </Flex.Responsive>
              <FastField name={`${namespace}.comtype`}>
                {({ field }) => {
                  if (!field.value) return null
                  return field.value === 'y' && (
                    <Box mt={responsive('1em', '2.25em')}>
                      <Input.FastField
                        readOnly={readOnly}
                        label="統編"
                        name={`${namespace}.company_number`}
                        labelFontSize="1.125em"
                      />
                    </Box>
                  )
                }}
              </FastField>
              <Flex.Responsive alignItems={responsive('auto', 'flex-end')} mt="2.25em">
                <Input.FastField readOnly={readOnly} type="tel" label="連絡電話" name={`${namespace}.phone1`} />
                <Input.FastField ml={responsive(0, '1em')} readOnly={readOnly} type="tel" label="手機" name={`${namespace}.phone2`} />
              </Flex.Responsive>
              <Input.FastField readOnly={readOnly} label="E-mail" type="email" mt="2.25em" name={`${namespace}.email`} note="Email如有誤繕或錯誤，將無法收到回覆訊息。" />
            </Box>
          </Flex>
          {!readOnly && (
            <Flex.Responsive mx="-1em" mt="1.25em">
              {useData.length <= 6 && (
                <Button.Purple
                  px={responsive('1rem', "5rem")}
                  mx="1em"
                  mt="1em"
                  leftIcon={MdAdd}
                  onClick={() => increase(n)}
                >
                  新增共同{n}
                </Button.Purple>
              )}
              {i > 0 && (
                <Button.Danger
                  px={responsive('1rem', "5rem")}
                  mx="1em"
                  mt="1em"
                  leftIcon={MdClose}
                  onClick={() => remove(i)}
                >移除共同{n} {i}</Button.Danger>
              )}
            </Flex.Responsive>
          )}
        </Board>
      )
    })
  )
}

export default ClaimForm
