import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Formik, Form, useFormikContext } from 'formik';
import { navigate } from "gatsby"
import * as yup from 'yup'
import size from 'lodash/size'
import mapValues from 'lodash/mapValues'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import useFetch from 'use-http'
// import styled from 'styled-components'

import Box from 'components/Box'
import Modal from 'components/Modal'
import Button from 'components/Button'
import ContentLayout from 'components/ContentLayout'

import ClaimProvider from 'contexts/apply/Provider'
import applyContext from 'contexts/apply/context'

import { responsive } from '../../components/ThemeProvider/theme';
import AllClaimData from './AllClaimData'
import messages from '../../utils/messages'
import idSchema from '../../utils/idSchema'
import twcityzip from './twcityzip';

import captchaShape from '../Captcha/shape'

const FirstSubmitBtn = ({ onClick }) => {
  const { validateForm, setErrors, setTouched } = useFormikContext()
  const handleClick = async () => {
    const errors = await validateForm()
    if (errors.captcha) delete errors.captcha

    if (size(errors)) {
      setErrors(errors)
      setTouched(errors)
    } else {
      onClick()
    }
  }
  return (
    <Button
      fontSize="1.25em"
      onClick={handleClick}
      type="button"
    >確認申請</Button>
  )
}

const addrShape = {
  city: yup.string().required(),
  zip: yup.string().required().matches(/^\d+$/, messages.numberOnly),
  address: yup.string().required(),
}

const personalDataShape = (required) => yup.object().shape({
  name: yup.string().required(),
  titid: yup.number().required(),
  phone1: yup.string(),
  phone2: yup.string(),
  ...addrShape,
  comtype: yup.string().oneOf(['y', 'n']).required(),
  company_number: yup.string().matches(/^\d+$/, messages.numberOnly).when('comtype', {
    is: 'y',
    then: yup.string().length(8).required(),
  }),
  national_number: required ? idSchema.required() : idSchema,
  email: yup.string().email().required(),
})

const driverShape = yup.object().shape({
  driverName: yup.string().required(),
  driverSts: yup.string().required(),
})

const pedShape = yup.object().shape({
  pedestrianName: yup.string().required(),
  pedestrianSts: yup.string().required(),
})

const getTrafficEventShape = index => yup.object()
  .when('carAccidentType', {
    is: 1,
    then: driverShape,
  })
  .when('carAccidentType', {
    is: 2,
    then: index ? driverShape : pedShape,
  })
  .when('carAccidentType', {
    is: 3,
    then: index ? pedShape : driverShape,
  })

const getValidationSchema = type => {
  const isTraffic = type === 'traffic'
  return yup.object().shape({
    apply: yup.array().of(personalDataShape(true)),
    versus: yup.array().of(personalDataShape()),
    happendate: yup.object({
      y: yup.number().required(),
      m: yup.number().required(),
      d: yup.number().required(),
      h: yup.number().required(),
      minute: yup.number().required(),
    }).required(),
    happenevent: yup.mixed().required(),
    happenplace: yup.object().shape(addrShape).required(),
    orgselect: yup.number().default(1).required(),
    traffic: yup.object().shape({
      apply: getTrafficEventShape(0),
      versus: getTrafficEventShape(1),
    }),
    ...(isTraffic ? {
      carAccidentType: yup.number().required(),
    } : {
      event: yup.object().shape({
        template: yup.string().required(),
      })
    }),
    captcha: captchaShape,
  })
}

const padLeft = (v, len) => String(v).padStart(len, '0')

const formatValues = obj => mapValues(obj, (v, k) => {
  if (k === 'birth') return [padLeft(v.y, 3), padLeft(v.m, 2), padLeft(v.d, 2)].join('')
  if (k === 'happendate') {
    return [padLeft(v.y, 3), padLeft(v.m, 2), padLeft(v.d, 2)].join('')
  }
  if (k === 'happenplace' && isObject(v)) {
    return [twcityzip.zip[v.zip], v.address].join('')
  }
  // if (k === 'happenevent' && isObject(v)) return JSON.stringify(v)
  if (isArray(v)) return v.map(vv => isObject(vv) ? formatValues(vv) : vv)
  if (isObject(v)) return formatValues(v)
  return v
})

const Claim = ({ src, pageContext: { id }, setOpen, ...props }) => {
  const { applyByKey } = useContext(applyContext)
  const [isOpen, handleOpen] = useState()
  const { post, response, error } = useFetch('/apply')
  const validationSchema = useMemo(() => getValidationSchema(id), [id])
  useEffect(() => {
    if (!id) {
      navigate('/apply')
    }
  })
  return (
    <ContentLayout
      title={id && applyByKey[id].text}
      {...props}
    >
      <Formik
        // initialValues={demoInput}
        initialValues={{
          apply: [{}],
          versus: [{}],
          traffic: {},
          carAccidentType: '1',
          captcha: {
            input: '',
          },
          event: {},
          orgselect: '1',
          kind: id === 'traffic' ? '2' : '1',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setFieldError, setFieldValue, setErrors }) => {
          const vToSubmit = Object.assign({}, values)
          vToSubmit.happentime = [padLeft(vToSubmit.happendate.h, 2), padLeft(vToSubmit.happendate.minute, 2)].join('')
          vToSubmit.happenzip = vToSubmit.happenplace.zip
          const formmatedValues = formatValues(vToSubmit)

          const data = await post(formmatedValues)
          if (error) {
            setFieldError('captcha.uuid', 'Refresh')
            if (response.status === 422) {
              if (data['captcha.input']) {
                setFieldError('captcha.uuid', 'Invalid')
              } else {
                setErrors(data)
              }
            } else if (response.status === 409) {
              setFieldValue('captcha.uuid', '')
              setFieldValue('captcha.input', '')
              setErrors(data)
              handleOpen(false)
            }  else {
              setErrors(error)
            }
          } else {
            if (data.webapplyforno) {
              navigate(`/apply/${id}/thanks?no=${data.webapplyforno}`)
            }
          }
          setSubmitting(false)
        }}
      >
        <Form>
          <AllClaimData id={id} />
          <Box textAlign="center" mt="3em">
            <FirstSubmitBtn onClick={() => handleOpen(true)} />
          </Box>
          <Modal p={responsive('2.625em 0.875em', '5.75em 2em')} isOpen={isOpen} onRequestClose={() => handleOpen(false)}>
            <AllClaimData isOpen={isOpen} id={id} handleOpen={handleOpen} />
          </Modal>
        </Form>
      </Formik>
    </ContentLayout>
  )
}

export default props => (
  <ClaimProvider>
    <Claim {...props} />
  </ClaimProvider>
)
